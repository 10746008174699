<template>
<div class="relative w-full">
    <div  @click="clicked" :class="['select-field-wrapper border border-gray-300 rounded relative',{'input-field__input--focused': isFocused },
       {'dropDown_input--empty' : this.selected == '' && required && this.isEmpty && !this.disabled }]">
       <v-select :value="selected" v-model="selected" class="h-full" :class="['input-field__input_dropdown']" 
        :disabled="disabled"
        ref="select" 
        :taggable="taggable"
        :clearable="clearable"
        :clearSearchOnBlur="onSelect"
        @paste="pasted"
        @keypress="speckeydown"
        @input="setSelected"
        @onMousedown="clickPlaceholder"
        :options="sortedArray"
        :selectable="(items) => CheckExist(items)"
       >
        <template v-slot:no-options>Loading {{placeholder}}</template>
       </v-select>
        <div
            @click="clickPlaceholder"
            :class="[
                'input-field__placeholder_dropdown z-40',
                {'input-field__placeholder_dropdown--focused': floatOn,'flex flex-row':required},
                {'bg-[#F8F8F8]':disabled}
            ]"
            >
            <div v-if="required" class=" flex text-red-600 pt-1 pr-1">
                <span v-text="'*'"  class="self-center"/>
            </div>
            <span v-text="placeholder"/>
        </div>
    </div>
</div>
</template>

<script>
import { defineComponent } from 'vue'
import "vue-select/dist/vue-select.css";

export default defineComponent({
    props: {
        id:{
            type:String,
            required:true,
        },
        taggable: {
            type: Boolean,
            required:false,
            default:false
        },
        items:{
            type:Array,
            required: false,
        },
        placeholder: {
            type:String,
            required: false,
        },
        required: {
            type:Boolean,
            required:false,
        },
        disabled: {
            type:Boolean,
            required:false,
        },
        type:{
            type:String,
            required:false,
        },
        uppercase: {
            type:Boolean,
            required:false,
        },
        componentId:{
            type:String,
            required:false,
        },
        submittedValue:{
            type:String,
            required:false,
        },
        clearable:{
            type:Boolean,
            required:false,
            default:false
        },
        notSorted:{
             type:Boolean,
            required:false,
        },
        disabled_items:{
            type:Array,
            required:false,
        }
    },
    data() {
        return {
        isFocused: false,
        isEmpty: false,
        selected: '',
        }
    },
    mounted(){
        if(this.submittedValue) {
          this.selected = this.submittedValue;
        //   this.isFocused = true;
          this.onSelect();
        }
    },
    
    computed:{
         filteredItems() {
            return this.items.map((item) => {
                // console.log(item.state.includes(this.filter));
                // if (item.state.toLowerCase().includes(this.filter.toLowerCase())) {
                // item.disabled = false;
                // } else {
                item.disabled = true;
                // }
                return item;
            });
        },
        floatOn() {
          return (this.isFocused || this.selected.length > 0) && !this.disabled ;
        },
        sortedArray: function() {
            let items = this.items;
            function compare(a, b) {
                if (a < b)
                return -1;
                if (a > b)
                return 1;
                return 0;
            }
            if(this.disabled_items) {
                let sorted = items.sort((a, b) => this.disabled_items.includes(a) - this.disabled_items.includes(b));
                // console.log(sorted)
               return sorted;
            } else {
                return this.notSorted?items:items.sort(compare);
            } 
            
        }
    },
    watch:{
        selected(e) {
            if(e == null){
                this.$emit('cleared');
            }
            // this.onSelect(e.target.value);
        }
    },
    methods:{
        speckeydown(e) {
            if (/^[*|\\":<>[\]{}`\\()'!#;@&$]*$/.test(e.key) && this.type !="email" || !this.taggable) {
                e.preventDefault();
            }
        },
        clearValue(){
            this.selected = '';
            this.isFocused = false;
            this.$emit('changed',{
            key:this.id,
            value:'',
           });
        },
        CheckExist(item){
            if(this.disabled_items){
                    var exists = this.disabled_items.some(function(field) {
                    return field === item
               });
                return !exists;
            }else{
                return true;
            }
           
        },
        onSelect() {
            
           this.isFocused = true;
           this.checkEmpty();
           this.selected = this.selected.toUpperCase();
           this.$emit('changed',{
            key:this.id,
            value:this.selected,
           });
        },
        clearSelected() {
            this.isFocused = false;
        },
        checkEmpty() {
            if((this.selected == '' || this.selected == null) && !this.disabled) {
                this.$emit("error",this.componentId);
                this.isEmpty = true;
            }else{
                this.isEmpty = false;
                this.$emit("clearError",this.componentId);
            }
        },
        pasted(e) {
          if(this.nopaste){
             this.$emit('pasted', e.clipboardData.getData('text'));
             e.preventDefault();
          }
        },
        setSelected(value)
        {
            if(value.target.value !='') {
                this.isFocused = true;
                this.$emit("clearError",this.componentId);
            } else {
                 this.isFocused = false;
            }
           
        },
        clicked() {
            if(!this.disabled){
              this.$emit('clicked',{key:this.id});
            }
            
        },
        clickPlaceholder() {
            this.isFocused = true;
            let input = this.$refs.select.$el.querySelector('input')
            input.focus();
            //gaga
            // this.$refs.select.typeAheadSelect();
            
        },
    },
})
</script>

<style>
.select-field-wrapper {
  /* padding-top: 3px; */
  position: relative;
}
.vs__selected{
    height: 100%;
}
.input-field__input_dropdown {
  height: 55px !important;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  background-color: #ffff;
  outline: none;
  border: 0px;
  border-radius: 5px !important;
}

.v-select
.vs--single,
.vs--searchable ,
.input-field__input_dropdown,
.vs__dropdown-toggle
{
    border:0px;
}

.v-select
.vs--single,
.vs--searchable,
.input-field__input_dropdown,
.vs__dropdown-toggle,
.vs__selected-options,
.vs__search {
    height: 100%;
}
.ant-picker-datetime-panel{
    flex-direction: column !important;
}
.ant-btn-primary{
    background-color: green !important;
    color:white !important;
}
.mobile-input-field {
  width: 100%;
  appearance:none;
  height: 100%;
  padding: 5px;
  background-color: #ffff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  outline: none;
}
.input-field__placeholder_dropdown {
  position: absolute;
  font-size: 18px;
  left: 12px;
  top: 19px;
  padding: 0 ;
  background: #fff;
  transition: all 0.3s;
  line-height: 12px;
  border-radius: 10px;
}
.input-field__placeholder_dropdown--focused {
  top: 4px;
  font-size: 10px;
  left: 10px;
}
.input-field__input--focused {
  border: 2px solid #016b00;
}
.dropDown_input--empty {
  border: 1px solid #db0404 !important;
}
.dropdown-container select::-ms-expand {
    display: none;
}
</style>

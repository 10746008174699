<template>
  <Container :back_ground_color="'items-center h-full max-w-7xl '">
      <Card :note="false" max_width="max-w-6xl overflow-y-auto" :title="'SUMMARY'" :service_type="this.$store.state.membership_details.mem_type" >
        <div class="flex flex-col w-full md:p-5">
            <div class="flex flex-row md:flex-row sm:flex-col xs:flex-col xs:space-x-0">
            <div class="w-full flex flex-col p-2">
                    <span v-text="'PERSONAL INFORMATION'" class="font-bold text-xl  "/>
                    <div class="grid grid-cols-2 border p-3  text-lg bg-white">
                            <span v-text="'Last Name'" class="font-bold"/>
                            <span v-text="': '+last_name" class="xs:text-md"/>
                            <span v-text="'First Name'" class="font-bold"/>
                            <span v-text="': '+first_name" class="xs:text-md"/>
                            <span v-text="'Middle Name'" class="font-bold"/>
                            <span v-text="': '+middle_name" class="xs:text-md"/>
                            <span v-text="'Birth Date'" class="font-bold"/>
                            <span v-text="': '+birth_date" class="xs:text-md"/>
                            <span v-text="'Birth Place'" class="font-bold"/>
                            <span v-text="': '+place_of_birth" class="xs:text-md"/>
                            <span v-text="'Gender'" class="font-bold"/>
                            <span v-text="': '+gender" class="xs:text-md"/>
                            <span v-text="'Citizenship'" class="font-bold"/>
                            <span v-text="': '+citizenship" class="xs:text-md"/>
                            <span v-text="'Civil Status'" class="font-bold"/>
                            <span v-text="': '+civil_status" class="xs:text-md"/>
                            <span v-text="'Branch of Service'" class="font-bold"/>
                            <span v-text="': '+branch_of_service" class="xs:text-md"/>
                            <span v-text="'Employment'" class="font-bold"/>
                            <span v-text="employment != null || employment != '' ?': '+employment:''" class="xs:text-md"/>
                            <span v-text="'Rank'" class="font-bold"/>
                            <span v-text="rank != undefined  || rank != null || rank !=''?': '+rank :''" class="xs:text-md"/>
                            <span v-text="'TIN'" class="font-bold"/>
                            <span v-text="': '+tin" class="xs:text-md"/>
                            <span v-text="'SSS No.'" class="font-bold"/>
                            <span v-text="': '+sss" class="xs:text-md"/>
                            <span v-text="'GSIS No.'" class="font-bold"/>
                            <span v-text="': '+gsis" class="xs:text-md"/>
                            <span v-text="'Email Address'" class="font-bold "/>
                            <span v-text="': '+email" class="breaker xs:text-md"/>
                            <span v-text="'Mobile No.'" class="font-bold"/>
                            <span v-text="': '+mobile_number" class="xs:text-md"/>
                            <span v-text="'AFP Serial No.'" class="font-bold"/>
                            <span v-text="': '+(afp_serial!=null || afp_serial!='')?': '+afp_serial:'??????'" class="xs:text-md"/>
                    </div>
                </div>
                <div class="w-full flex flex-col h-full p-2 ">
                    <span v-text="'ADDRESS'" class="font-bold text-xl  "/>
                    <div class="flex flex-col h-full border p-7 space-y-2 bg-white">
                        <span v-text="'PERMANENT ADDRESS'" class="font-bold text-lg"/>
                        <div class="grid grid-cols-2 sm:text-lg md:text-lg xs:text-md ">
                                <span v-text="'Street'" class="font-bold"/>
                                <span v-text="': '+resstreet_address" class="xs:text-md"/>
                                <span v-text="'Barangay'" class="font-bold"/>
                                <span v-text="': '+resbrngy" class="xs:text-md"/>
                                <span v-text="'Zip Code'" class="font-bold"/>
                                <span v-text="reszip_code != null ?': '+reszip_code: ''" class="xs:text-md"/>
                                <span v-text="'Province'" class="font-bold"/>
                                <span v-text="': '+resprovince" class="xs:text-md"/>
                                <span v-text="'City'" class="font-bold"/>
                                <span v-text="': '+rescity" class="xs:text-md"/>
                        </div>
                        <span v-text="'SECONDARY ADDRESS'" class="font-bold text-lg"/>
                        <div class="grid grid-cols-2 sm:text-lg md:text-lg xs:text-md bg-white">
                                <span v-text="'Street'" class="font-bold"/>
                                <span v-text="': '+altstreet_address" class="xs:text-md"/>
                                <span v-text="'Barangay'" class="font-bold"/>
                                <span v-text="': '+altbrngy" class="xs:text-md"/>
                                <span v-text="'Zip Code'" class="font-bold"/>
                                <span v-text="altzip_code != null?': '+altzip_code:''" class="xs:text-md"/>
                                <span v-text="'Province'" class="font-bold"/>
                                <span v-text="': '+altprovince" class="xs:text-md"/>
                                <span v-text="'City'" class="font-bold"/>
                                <span v-text="': '+altcity" class="xs:text-md"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full p-2">
                <span v-text="'FAMILY INFORMATION'" class="font-bold text-xl  "/>
                <div class="w-full border grid grid-cols-3 p-2 text-lg bg-white">
                        <span v-text="'Mother\'s Maiden Name'" class="font-bold"/>
                        <span v-text="': '+mo_last_name + ' '+ mo_first_name +' '+mo_middle_name" class="xs:text-md col-span-2" />
                        <span v-text="'Father\'s Name'" class="font-bold"/>
                        <span v-text="': '+fa_last_name +' '+ fa_first_name +' '+fa_middle_name" class="col-span-2 xs:text-md" />
                        <span v-text="'Spouse Name'" class="font-bold"/>
                        <span v-text="': '+sp_last_name  +' '+ sp_first_name +' '+ sp_middle_name" class="col-span-2 xs:text-md"/>
                        <span v-if="sp_last_name1 && sp_last_name1" v-text="' '" />
                        <span v-if="sp_last_name1 && sp_last_name1" v-text="': '+sp_last_name1  +' '+ sp_first_name1 +' '+ sp_middle_name1" class="col-span-2 xs:text-md"/>
                        <span v-if="sp_last_name2 && sp_last_name2" v-text="' '" />
                        <span v-if="sp_last_name2 && sp_last_name2" v-text="': '+sp_last_name2  +' '+ sp_first_name2 +' '+ sp_middle_name2" class="col-span-2 xs:text-md"/>
                        <span v-if="sp_last_name3 && sp_last_name3" v-text="' '" />
                        <span v-if="sp_last_name3 && sp_last_name3" v-text="': '+sp_last_name3  +' '+ sp_first_name3 +' '+ sp_middle_name3" class="col-span-2 xs:text-md"/>
                         <span v-text="'Beneficiaries'" class="font-bold"/>
                        <div class="col-span-2 grid grid-cols-3">
                            <span v-for="(items,index) in beneficiaries" v-bind:key="items.name" v-text="beneficiaries[index].beneficiary_name != undefined ?': ' + beneficiaries[index].beneficiary_name:'' " class="col-span-3 xs:text-md"/>
                        </div>
                </div>
            </div>
            <div class="w-full p-2">
                <span v-text="'OTHER INFORMATION'" class="font-bold text-xl  "/>
                <div class="w-full border grid grid-cols-3 p-2 text-lg bg-white">
                        <span v-text="'Interview PSO Date & Time'" class="font-bold"/>
                        <span v-text="': '+interview_date +' at ' +pso" class="col-span-2" />
                        <span v-text="'Mode of Interview'" class="font-bold"/>
                        <span v-text="': '+interview_mode" class="col-span-2" />
                        <!-- <span v-text="'Referral Name'" class="font-bold"/>
                        <span v-text="': '+(ref_name?ref_name:'')" class="col-span-2" />
                        <span v-text="'Referral Acct/Serial No.'" class="font-bold" /> -->
                        <!-- <span v-text="': '+(ref_account?ref_account:'')" class="col-span-2"  /> -->
                        <span v-text="'Sponsor Name'" class="font-bold"/>
                        <span v-text="': '+(sponsor_name?sponsor_name:'')" class="col-span-2" />
                        <span v-text="'Sponsor Acct/Serial No.'" class="font-bold" />
                        <span v-text="': '+(sponsor_acct?sponsor_acct:'')" />
                </div>
            </div>
        </div>
      </Card>
  </Container>  
</template>
<script>
import { defineComponent } from 'vue'
import Container from '@/components/sections/ContainerView.vue';
import Card from '@/components/controls/CardView.vue';
// import TextField from '@/components/controls/TextBoxField.vue';
import moment from 'moment';
import mixin from "@/mixin";

export default defineComponent({
  components:{
    Container,
    Card,
  },
  data(){
    return {
        ref_account:'',
        ref_name:'',
        resstreet_address:'',
        reszip_code:'',
        resprovince:'',
        rescity:'',
        resbrngy:'',
        altstreet_address:'',
        altzip_code:'',
        altprovince:'',
        altcity:'',
        altbrngy:'',
        first_name: '',
        last_name:'',
        middle_name:'',
        afp_serial:'',
        rank:'',
        birth_date:'',
        place_of_birth:'',
        gender:'',
        civil_status:'',
        tin:'',
        mobile_number:'',
        email:'',
        branch_of_service:'',
        employment:'',
        sp_first_name:'',
        sp_last_name:'',
        sp_middle_name:'',
        sp_first_name1:'',
        sp_last_name1:'',
        sp_middle_name1:'',
        sp_first_name2:'',
        sp_last_name2:'',
        sp_middle_name2:'',
        sp_first_name3:'',
        sp_last_name3:'',
        sp_middle_name3:'',
        mo_first_name:'',
        mo_last_name:'',
        mo_middle_name:'',
        fa_first_name:'',
        fa_last_name:'',
        fa_middle_name:'',
        beneficiaries:[],
        pso:'',
        interview_date:'',
        fron:'',
        back:'',
        signature:'', 
        citizenship:'',
        sss:'',
        gsis:'',
        membership_type:'',
        interview_mode:'',
        sponsor_name:'',
        sponsor_acct:'',
    }
  },
   mixins: [
    mixin,
  ],
  methods:{
    applyChanges(){
       let store = this.$store;
      let state = store.state.membership_details;

        this.interview_date = state.interview_date;
        this.pso = state.pso_text;
        this.first_name = state.first_name;
        this.last_name = state.last_name;
        this.middle_name = state.middle_name==null?"":state.middle_name;
        this.rank = state.ranks_text != undefined ? state.ranks_text :'';
        this.birth_date = moment(state.birth_date).format('MM/DD/YYYY');
        this.place_of_birth = state.place_of_birth;
        this.gender = state.gender_text;
        this.civil_status = state.civil_status_text;
        this.afp_serial = state.afp_serial_number!=null?state.afp_serial_number:"";
        this.branch_of_service = state.branch_of_service_text;
        this.employment = state.employee_status_text != null ?state.employee_status_text:'';
        this.tin = state.tin==null?"":state.tin;
        this.mobile_number = state.mobile;
        this.email = state.email
        this.mo_first_name = state.mofirst_name;
        this.mo_last_name = state.molast_name;
        this.mo_middle_name = state.momiddle_name != null ? state.momiddle_name:'';  
        this.fa_first_name = state.fafirst_name != null ? state.fafirst_name:'';
        this.fa_last_name = state.falast_name  != null ? state.falast_name:'' ;
        this.fa_middle_name = state.famiddle_name  != null ? state.famiddle_name:'' ; 
        this.sp_first_name = state.spfirst_name != null ?state.spfirst_name:'';
        this.sp_last_name = state.splast_name != null ?state.splast_name:'';
        this.sp_middle_name = state.spmiddle_name != null ?state.spmiddle_name:''; 
        this.sp_first_name1 = state.spfirst_name2;
        this.sp_last_name1 = state.splast_name2;
        this.sp_middle_name1 = state.spmiddle_name2; 
        this.sp_first_name2 = state.spfirst_name3;
        this.sp_last_name2 = state.splast_name3;
        this.sp_middle_name2 = state.spmiddle_name3; 
        this.sp_first_name3 = state.spfirst_name4;
        this.sp_last_name3 = state.splast_name4;
        this.sp_middle_name3 = state.spmiddle_name4; 
        this.resstreet_address = state.resstreet_address;
        this.reszip_code = state.reszip_code;
        this.resprovince = state.resprovince;
        this.rescity = state.rescity;
        this.resbrngy = state.resbrngy;
        this.altstreet_address = state.altstreet_address;
        this.altzip_code = state.altzip_code;
        this.altprovince = state.altprovince;
        this.altcity = state.altcity;
        this.altbrngy = state.altbrngy;
        this.signature = state.signature;
        this.front = state.front;
        this.back = state.back;
        this.ref_account = state.ref_account;
        this.ref_name = state.ref_name;
        this.sss = state.sss_number;
        this.gsis = state.gsis_number;
        this.citizenship = state.citizenship;
        this.beneficiaries = state.beneficiaries;
        this.membership_type = state.mem_type;
        this.interview_mode = state.interview_mode;
        this.sponsor_name = state.sponsor_name;
        this.sponsor_acct = state.sponsor_nr;

        if(state.interview_mode == 'SIG'){
          this.interview_mode = "SIGNAL"
        }
        if(state.interview_mode == 'VIB'){
          this.interview_mode = "VIBER"
        }
        if(state.interview_mode == 'ZOM'){
          this.interview_mode = "ZOOM"
        }
    }
  }
})
</script>
<style>
.breaker{
  line-break:anywhere;
}
</style>

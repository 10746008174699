<template>
    <div class="bg-white flex w-full h-full">
      <Modal v-show="show_legal_notice"
        v-bind:display="show_legal_notice"
        z_value="z-50"
        :default_width="'max-w-xl'"
        id='vote_modal'>
       <div class="w-full p-5 flex flex-col text-2xl font-bold text-theme-primary">
          Legal Notice
        </div>
        <div class="w-full flex flex-row text-xl  p-2">
          Do you hereby agree that all information that will be sent by PAFCPIC via SMS and/or email shall be considered a personal notice and legally binding?
          </div>
         <div class="w-full flex flex-row text-xl  p-2 space-x-1"  >
            <Button :button_text="'I AGREE'" @clicked="closeRequirements"/>
          </div>
        </Modal>
        <Modal v-show="show_error_message"
        v-bind:display="show_error_message"
        z_value="z-50"
        :default_width="'max-w-xl'"
        id='vote_modal'>
        <div class="w-full p-2 flex flex-col text-2xl font-bold text-theme-primary">
          REMINDERS
        </div>
        <div class="w-full flex flex-row text-xl  p-1">
          {{error_message}}
          </div>
         <div class="w-full flex flex-row text-xl  p-2 space-x-1"  >
            <Button :button_text="'OK'" @clicked="show_error_message=false"/>
          </div>
        </Modal>
        <img src="@/assets/images/WEB2.jpg" class="fixed h-screen w-full  "/>
        <div class="w-full h-full flex flex-col xs:p-2 p-10 justify-center items-center z-40 space-y-1" v-if="!show_legal_notice">
            <Privacy class="w-full border-2 overflow-y-auto xs:h-full h-5/6 max-w-4xl -mt-10 xs:-mt-0 border-gray-300 rounded" v-if="!show_terms" @done="done"/>
            <TermsView class="w-full border-2 overflow-y-auto xs:h-full h-5/6 max-w-4xl -mt-11 xs:-mt-0  border-gray-300 rounded" v-if="show_terms"  @finished="finished"/>
            <div class="flex flex-row w-full max-w-4xl " v-if="show_terms">
                <Checkbox  id="agree_ck" class="text-left mt-auto mb-auto" :checkbox_width="32" @message="showErrorMessage" @changed="update" :disabled="enable_proceed"/>
                <div class="flex flex-wrap mt-auto mb-auto font-bold md:text-lg">
                    <span v-text="'I agree and understood the End User License Agreement and Privacy Policy'"/>
                </div>
            </div>
            <div class="w-full flex -pt-1 max-w-4xl">
                <Button text_size="text-2xl xs:text-lg" height="h-14 xs:h-10" :button_text="button_text" @message="showErrorMessage" class="" @clicked="showTerms" v-if="!show_terms" :disabled="enable_next"/>
                <Button text_size="text-2xl xs:text-lg" height="h-14 xs:h-10" :button_text="button_text" @message="showErrorMessage" class="" @clicked="proceed" v-if="show_terms" :disabled="!aggree && show_terms"/>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import Privacy from "@/components/sections/PrivacyPolicyView.vue";
import TermsView from "@/components/sections/TermsOfUserView.vue";
import Checkbox from '@/components/controls/CheckBox.vue';
import Button from "@/components/controls/ButtonControl.vue";
import Modal from '@/components/controls/ModalControl.vue';

export default defineComponent({
    components:{
        Privacy,
        TermsView,
        Button,
        Checkbox,
        Modal
    },
    data(){
        return{
            show_terms: false,
            button_text:"NEXT",
            enable_next:true,
            enable_proceed:true,
            aggree:false,
            show_legal_notice:false,
            show_error_message:false,
            error_message:"",
        }
    },
    mounted(){
        this.show_legal_notice = true;
        // window.location.reload();
    },
    methods:{
        closeRequirements(){
            this.show_legal_notice = false;
        },
        showErrorMessage(){
            // console.log("151125");
            if(this.show_terms){
                this.error_message = "Please scroll down to ensure you have read and fully understood the End-User Agreement before proceeding."
            }else{
                this.error_message = "Please scroll down to ensure you have read and fully understood the Privacy Policy before proceeding."
            }
            this.show_error_message = true;
        },
        done(){
            this.enable_next = false;
        },
        finished(){
            // console.log(this.enable_proceed);
            this.enable_proceed=false;
        },
        showTerms(){
            this.show_terms = true;
            this.button_text = "PROCEED";
        },
        proceed(){
            this.$router.push('/application');
        },
        update(e){
            this.aggree = e;
        },
    }
})
</script>

<style scoped>

</style>
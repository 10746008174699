<template>
    <div class="w-full flex flex-col" v-if="this.show_field">
          <div  class="flex flex-row bg-white relative"  :class="['input-field__input input-field-wrapper',{'input-field__input--focused': on_focus_theme},{'input-field__input--error': empty==true && required}]">
            <input type="text" placeholder="+63" v-show="text_type=='mobile'" disabled class=" max-w-xxs text-center -mt-1 border-r border-r-gray-400"/>
              <input v-if="id=='tin'" 
                  autocomplete="off"
                  :id="id" 
                  ref="input"
                  :type="'tel'" 
                  v-model="curr_val"
                  v-mask="'###-###-###'"
                  @keyup.backspace="backspace"
                  @paste="pasted"
                  @keypress="checkEmpty"
                  @keyup.enter="enter"
                  @focus="onFocus"
                  @blur="onBlur"
                  class="w-full max-w-full bg-transparent appearance-none leading-6 p-3 text-gray-700 text-base focus:outline-none"  
              />
              <input v-else
                  
                  :id="id" 
                  ref="input"
                  autocomplete="off"
                  @input="uppercase?this.curr_val = $event.target.value.toUpperCase():this.curr_val = $event.target.value"
                  :type="text_type=='mobile'?'number':text_type" 
                  v-model="curr_val"
                  :disabled="disable"
                  @keyup.backspace="backspace"
                  @keypress="speckeydown"
                  @keyup.enter="enter"
                  @focus="onFocus"
                  @blur="onBlur"
                  @paste="pasted"
                  class="w-full max-w-full bg-transparent appearance-none leading-6 p-3 text-gray-700 text-base focus:outline-none " 
                  :class="[{'bg-gray-100 rounded top-0':disable}]"
              />
              <div
                  @click="clickPlaceholder"
                  :class="[
                      'input-field__placeholder',{'input-field-number__placeholder--focused':isFocused && text_type=='mobile','flex flex-row':required},
                      { 'input-field__placeholder--focused': isFocused },{'input-field-number__placeholder ':text_type == 'mobile'}
                  ]"
                  >
                  <div v-if="required" class=" flex text-red-600 pt-1 pr-1">
                    <span v-text="'*'"  class="self-center"/>
                  </div>
                  <span v-text="placeholder"/>
              </div>
              <div v-if="this.checked && !this.loading && this.curr_val.length>0" class="px-2">
                  <div class="w-icon-24 h-icon-24 peek-margin" v-show="this.exist">
                    <Icons id="textbox_icon" v-bind:source="'images/alert/papsi_circle_check.svg'" @click="togglePeeker" :width="24" />
                  </div>
                   <div class="w-icon-24 h-icon-24 peek-margin" v-show="!this.exist">
                    <Icons id="textbox_icon" v-bind:source="'images/alert/papsi_cancel.svg'" @click="togglePeeker" :width="24" />
                  </div>
              </div>
              <div v-if="this.loading" class="px-2">
                  <div class="w-icon-24 h-icon-24 peek-margin">
                    <Icons id="textbox_icon" v-bind:source="'images/loader/papsi_loading_icon.gif'" @click="togglePeeker" :width="24" />
                  </div>
              </div>
              <div v-if="this.has_icon" class="px-2">
                  <div class="w-icon-24 h-icon-24 peek-margin">
                      <Icons id="textbox_icon" v-bind:source="getImgSrc()" @click="togglePeeker" :width="24" />
                  </div>
              </div>
          </div>
           <div class="text-red-600" v-if="error_message && (id =='email' || id=='mobile' || id=='tin')">
            {{error_message}}
          </div>
           <div class="text-red-600" v-if="error_message && this.curr_val <=500 && this.sc && (id !='email' || id!='mobile' || id!='tin')">
            {{error_message}}
          </div>
          <div class="text-red-600" v-if="valid_length">
            please enter minimum of {{minimum_length +1}} characters
          </div>
          
        <div v-if="strength && curr_val" class="mt-2">
          <PasswordStrength v-bind:value="curr_val" @score="passwordStrength" />
        </div>
    </div>
 
</template>
<script>
import { defineComponent } from 'vue';
import Icons from './Icon.vue';
import PasswordStrength from './PasswordStrength.vue';
import User from '@/services/User';
export default defineComponent({
    components:{
      Icons,
      PasswordStrength
    },
    props:{
            id: {
                type:String,
                required:true,
            },
            type: {
                type:String,
                required:false,
                default:'text'
            },
            placeholder: {
                type:String,
                required:false,
            },
            initial:{
                type:String,
                required:false
            },
            has_error: {
                type:Boolean,
                required:false,
            },
            peekable: {
                type: Boolean,
                required: false,
                default: false,
            },
            strength: {
                type: Boolean,
                required: false,
                default: false,
            },
            uppercase:{
              type:Boolean,
              required: false,
            },
            nonumbers:{
              type:Boolean,
              required:false,
            },
            nospecial:{
              type:Boolean,
              required:false,
            },
            max_length: {
              type:Number,
              required:false,
            },
            minimum_length:{
              type:Number,
              required:false,
            },
            required:{
              type:Boolean,
              required: false,
            },
            nopaste:{
              type:Boolean,
              required:false,
            },
            special_function:{
               type:Boolean,
               required:false,
            },
            disable:{
              type:Boolean,
              required:false,
            },
            sc:{
              type:Boolean,
              required:false
            },
    },
    data(){
        return {
            curr_val:'',
            value:'',
            show_field:true,
            text_type:'text',
            isFocused:false,
            empty:false,
            error:false,
            has_icon: false,
            on_focus_theme:false,
            visible: false,
            exist:false,
            checked:false,
            icon:'',
            invalid_email:false,
            invalid_number:false,
            error_message:'',
            valid_length:false,
            loading:false,
        }
    },
    watch: {
        curr_val(value) {
          if(value){
            this.$emit("changed", {
                key: this.id,
                value: value,
            });
            this.value = value;
          }
        },
        initial(value){
          if(value>0){
            this.curr_val = value;
          }else{
            this.curr_val = 0
          }
        }
    },
    mounted(){
        if(this.type) {
            this.text_type = this.type;
        }
         if(this.type == "Password" && this.peekable)
          {
              this.has_icon = true;
          }
        if(this.sc){
          this.isFocused = true;
        }
        this.curr_val = this.initial;
    },
    
    methods:{
        getImgSrc() {
            return !this.visible ? "images/papsi_invi_icon.svg" : "images/papsi_vis_icon.svg";
        },
        backspace(e){
          if(e.target.value.length == 0){
            this.$emit("changed", {
                key: this.id,
                value: '',
            });
            this.value = '';
          }
        },
        showField() {
          this.show_field = true;
        },
        hideField(){
          this.show_field = false;
        },
        changedInitial(e){
          this.curr_val = e;
        },
        clickPlaceholder() {
            if(!this.disable) {
              this.$refs.input.focus();
              this.isFocused = true;
            }
        },
        passwordStrength(n) {
            this.$emit('score', n);
        },
        onFocus() {
            this.isFocused= true;
            this.on_focus_theme = true;
        },
        enter() {
            this.$emit('enter');
        },
        onBlur() {
            let that = this;
            let store = that.$store;
            let state = store.state.membership_details;
            // that.isFocused= false;
            that.on_focus_theme = false;
            
            if(that.special_function &&  that.value.length > 0){
              that.loading = true;
               that.$emit('loading');
              User.findAccountNumber(that.value).then(function(){
                  that.loading = false;
                  that.exist = true;
                  that.checked =true;
                   state.exist = true;
                   that.$emit('done');
              }).catch(function(){
                 User.findSerialNumber(that.value).then(function(){
                  that.loading = false;
                  that.exist = true;
                  that.checked =true;
                  state.exist = true;
                  that.$emit('done');
                 }).catch(function(){
                  that.$emit('done');
                  that.loading = false;
                  that.checked =true;
                  that.exist = false;
                  state.exist = false;
                 });
              });
            }
            if(that.value.length ==0){
                that.checked =false;
                that.isFocused= false;
            }
        },
        getValue() {
            return this.value != null ? this.value : this.curr_value;
        },
        checkEmpty() {
          let that = this;
          let store = that.$store;
          let state = store.state.membership_details;

          if(!that.curr_val && that.sc) {
            if(that.curr_val <=500 && that.sc){
              console.log(that.curr_val);
              that.error_message = "Please enter greater than or equal to 500" 
              that.empty = true;
            }
            that.empty = true;
            if(state.branch_of_service != "DEP"){
              that.$emit('hasError',that.id);
            }
            
          } else {
            that.empty = false;
            that.$emit('clearError',that.id);
          }
        },
        pasted(e) {
          if(this.nopaste){
             this.$emit('pasted', e.clipboardData.getData('text'));
             e.preventDefault();
          }
        },
        togglePeeker() {
            if(this.visible)
            {
                this.text_type = "password";
                this.visible = false;
            }
            else
            {
                this.text_type = "text";
                this.visible = true;
            }
        },
        checkValidNumber() {
          if(/^(9)\d{9}$/.test(this.curr_val)) {
            this.empty = false;
            this.invalid_number = false;
          }else{
            this.empty = true;
            this.invalid_number = true;
          }
        },
        checkDuplicate() {
          let that = this;
          let payload;

          if(that.id == 'tin' && that.curr_val) {
              payload = {
              type:5,
              value:that.curr_val,
            }
          }
          if(that.id =='mobile' && that.curr_val) {
            payload = {
              type:3,
              value:'+63'+that.curr_val,
            }
          }
          if(that.id=='email' && that.curr_val) {
              payload = {
              type:2,
              value:this.curr_val,
            }
          }
          User.testData(payload).then(function(){
             that.error_message ='';
          }).catch(function(error){
             
            if(that.id=='mobile') {
              that.empty = true;
            }
            if(that.id=='email') {
              that.empty = true;
            }
            if(that.id=='tin') {
              that.empty = true;
            }
            that.error_message = error.response.data.message;
          });
        },
        validateEmail() {
          if (/^\w+([\\.-]?\w+)*@\w+([\\.-]\w+)*(\.\w{2,3})+$/.test(this.curr_val)) {
              this.invalid_email = false;
              this.empty = false;
          } else {
              this.empty = true;
              this.invalid_email = true;
          }
        },
        clearText() {
          let that = this;
          that.curr_val = "";
          that.isFocused = false;
          // that.onBlur();
          // this.floatOn;
          // 
        },
        checkLength(){
          if(this.curr_val != ''){
            if(this.curr_val.length == (this.minimum_length + 1) || this.curr_val.length == (this.max_length + 1)){
            this.valid_length = false;
            }else {
              this.valid_length = true;
            }
          }
        },
        speckeydown(e) {
            this.checkEmpty();
            if (/^[*|\\":<>[\]{}`\\()'?/!.#%;^=_@&+$0,-9]*$/.test(e.key) && this.nonumbers && this.nospecial) {
                e.preventDefault();
            }
            if(this.type == 'mobile' && /^(e|E)*$/.test(e.key) ){
                 e.preventDefault();
            }
            if(/^["`']*$/.test(e.key) && this.id == 'email' ) {
               e.preventDefault();
            }
            if(/^[*|\\":<>[\]{}`\\()'?/!.#%;^=_@&+$]*$/.test(e.key) && this.nospecial) {
                e.preventDefault();
            }
            if(/^[0-8]*$/.test(e.key) && this.text_type == 'mobile' && e.target.value.length<1) {
                e.preventDefault();
            }
            if(e.target.value.length >this.max_length) {
              e.preventDefault();
            }
        },     
           
    },
    computed: {
        floatOn() {
          if(this.curr_val || this.curr_val == 0) {
            return this.isFocused || this.value.toString().length > 0;
          }else {
            return this.isFocused;
          }
          
        },
    },

});
</script>
<style>
.input-field__input {
  width: 100%;
  height: 55px !important;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  outline: none;
}
.input-field__static {
  width: 100%;
  /* margin-top: -10px; */
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  border-right: 1px solid #bcbcbc;
  outline: none;
}
.input-field__input--error {
  border: 1px solid #f00f0f !important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.input-field-wrapper {
  padding-top: 3px;
  position: relative;
}
.input-field__input {
  width: 100%;
  height: 100%;
   font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  outline: none;
}
.input-field__input--focused {
  border: 2px solid #016b00;
}
.input-field__input--empty {
  border: 1px solid #db0404;
}
.input-field__placeholder {
  position: absolute;
  font-size: 16px;
  left: 12px;
  top: 19px;
  padding: 0 5px;
  background: transparent;
  transition: all 0.3s;
  line-height: 12px;
}
.input-field-number__placeholder {
  position: absolute;
  font-size: 16px;
  left: 5rem;
  top: 19x;
  padding: 0 5px;
  background: transparent;
  transition: all 0.3s;
  line-height: 12px;
}
.input-field__placeholder--focused {
  top: 5px;
  font-size: 10px;
  left: 2px;
}
.input-field-number__placeholder--focused {
  top: 5px;
  font-size: 10px;
  left: 5rem;
  white-space: nowrap;

}
.peek-margin {
    margin-top: 11px;
}
</style>
